import React, { useState, useRef, useEffect } from 'react';
import { Send, Bot, User, Trash2, Settings, X } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const App = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [conversationId, setConversationId] = useState(Date.now().toString());
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim()) {
      const newMessage = { type: 'user', content: input };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setInput('');
      setIsLoading(true);

      try {
        const response = await fetch("https://backend.cc.frederik-pfeiffer.com/api/chat", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: input, conversationId }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let aiResponse = "";

        setMessages(prevMessages => [...prevMessages, { type: 'ai', content: '' }]);

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              const data = line.slice(6);
              if (data === '[DONE]') {
                setIsLoading(false);
                return;
              }
              try {
                const parsed = JSON.parse(data);
                if (parsed.content) {
                  aiResponse += parsed.content;
                  setMessages(prevMessages => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1].content = aiResponse;
                    return newMessages;
                  });
                }
              } catch (error) {
                console.error('Error parsing JSON:', error);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error:', error);
        setMessages(prevMessages => [...prevMessages, { type: 'error', content: 'Failed to get AI response. Please try again.' }]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const clearChat = () => {
    setMessages([]);
    setConversationId(Date.now().toString());
  };

  const MessageBubble = ({ message }) => (
    <div className={`flex mb-4 ${message.type === 'user' ? 'justify-end' : 'justify-start'} animate-fade-in`}>
      <div className={`flex items-start space-x-2 max-w-[70%] ${message.type === 'user' ? 'flex-row-reverse' : ''}`}>
        <div className={`p-2 rounded-full ${
          message.type === 'user' ? 'bg-indigo-600' : 
          message.type === 'error' ? 'bg-red-500' : 'bg-teal-500'
        } shadow-lg`}>
          {message.type === 'user' ? <User size={24} className="text-white" /> : <Bot size={24} className="text-white" />}
        </div>
        <div className={`p-3 rounded-lg shadow-md ${
          message.type === 'user' ? 'bg-indigo-600 text-white' : 
          message.type === 'error' ? 'bg-red-100 text-red-600' : 'bg-white text-gray-800'
        } backdrop-blur-sm bg-opacity-90 markdown-content`}>
          {message.type === 'user' ? (
            message.content
          ) : (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
              {message.content}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </div>
  );

  const WelcomeScreen = () => (
    <div className="flex flex-col items-center justify-center h-full animate-fade-in">
      <div className="w-32 h-32 mb-8 relative">
        <div className="absolute inset-0 bg-teal-500 rounded-full animate-pulse"></div>
        <Bot size={80} className="text-white absolute inset-0 m-auto" />
      </div>
      <h2 className="text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-indigo-500">
        Hey, ich bin Tribe 42
      </h2>
      <p className="text-gray-300 text-center max-w-md">
        Ich helfe dir deinen Traumjob zu endecken. Willst du dich mir vorstellen :D
      </p>
    </div>
  );

  return (
    <div className="flex flex-col h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
      <header className="bg-gray-800 bg-opacity-50 backdrop-blur-md p-4 flex justify-between items-center border-b border-gray-700">
        <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-indigo-500">Tribe 42</h1>
        <button onClick={() => setShowSettings(!showSettings)} className="p-2 rounded-full hover:bg-gray-700 transition-colors">
          <Settings size={24} className="text-teal-400" />
        </button>
      </header>
      <main className="flex-grow overflow-auto p-4 space-y-4">
        {messages.length === 0 ? (
          <WelcomeScreen />
        ) : (
          <>
            {messages.map((message, index) => (
              <MessageBubble key={index} message={message} />
            ))}
            {isLoading && (
              <div className="flex justify-start mb-4 animate-fade-in">
                <div className="flex items-center space-x-2">
                  <div className="p-2 rounded-full bg-teal-500 shadow-lg">
                    <Bot size={24} className="text-white" />
                  </div>
                  <div className="p-3 rounded-lg bg-white bg-opacity-90 backdrop-blur-sm shadow-md">
                    <div className="flex space-x-2">
                      <div className="w-3 h-3 bg-teal-500 rounded-full animate-bounce"></div>
                      <div className="w-3 h-3 bg-teal-500 rounded-full animate-bounce" style={{animationDelay: '0.2s'}}></div>
                      <div className="w-3 h-3 bg-teal-500 rounded-full animate-bounce" style={{animationDelay: '0.4s'}}></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </>
        )}
      </main>
      <footer className="p-4 border-t border-gray-700 bg-gray-800 bg-opacity-50 backdrop-blur-md">
        <form onSubmit={handleSubmit} className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="flex-grow p-2 bg-gray-700 text-white border-none rounded-l-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
            placeholder="Schreibe eine Nachricht..."
          />
          <button type="submit" className="bg-teal-500 text-white p-2 rounded-r-lg hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 transition-colors">
            <Send size={24} />
          </button>
        </form>
        <div className="flex justify-end mt-2">
          <button onClick={clearChat} className="text-red-400 hover:text-red-300 flex items-center transition-colors">
            <Trash2 size={20} className="mr-1" /> Clear Chat
          </button>
        </div>
      </footer>
      {showSettings && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-blur-sm animate-fade-in">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl w-96">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-teal-400">Settings</h2>
              <button onClick={() => setShowSettings(false)} className="text-gray-400 hover:text-white transition-colors">
                <X size={24} />
              </button>
            </div>
            <div className="mb-4">
              <label htmlFor="apiKey" className="block mb-2 text-sm font-medium text-gray-300">API Key:</label>
              <input
                type="password"
                id="apiKey"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="w-full p-2 bg-gray-700 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-teal-500 text-white"
                placeholder="Enter your API key"
              />
            </div>
            <button onClick={() => setShowSettings(false)} className="w-full bg-teal-500 text-white p-2 rounded hover:bg-teal-600 transition-colors">
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;